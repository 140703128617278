import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import renderHTML from 'react-render-html';
import '../styles/components/Leaderboard.scss';

const Leaderboard = ({ brandCover, style }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              google_desktop_leaderboard
              google_tablet_leaderboard
              google_mobile_leaderboard
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        // eslint-disable camelcase
        google_desktop_leaderboard: gdl,
        google_tablet_leaderboard: gtl,
        google_mobile_leaderboard: gml,
      } = data.allWordpressAcfOptions.edges[0].node;

      return (
        <section className="leaderboard" style={style}>
          <MediaQuery minWidth={992}>
            {brandCover.desktop ? renderHTML(brandCover.desktop)
              : gdl && (
              <div className="advertisement">
                {renderHTML(gdl)}
              </div>
              )
            }
          </MediaQuery>
          <MediaQuery minWidth={760} maxWidth={992}>
            {brandCover.tablet ? renderHTML(brandCover.tablet)
              : gtl && (
                <div className="advertisement">
                  {renderHTML(gtl)}
                </div>
              )
            }
          </MediaQuery>
          <MediaQuery maxWidth={760}>
            {brandCover.mobile ? renderHTML(brandCover.mobile)
              : gml && (
                <div className="advertisement">
                  {renderHTML(gml)}
                </div>
              )
            }
          </MediaQuery>
        </section>
      );
    }}
  />
);

Leaderboard.propTypes = {
  brandCover: PropTypes.shape(PropTypes.string),
  style: PropTypes.shape(PropTypes.any),
};

Leaderboard.defaultProps = {
  brandCover: { desktop: '', tablet: '', mobile: '' },
  style: {},
};

export default Leaderboard;
