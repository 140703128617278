import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
  constructor(props) {
    super(props);
    this.tab = React.createRef();
  }

  componentDidUpdate() {
    this.tab.current.scrollTop = 0;
  }

  render() {
    const { children } = this.props;
    return (
      <div ref={this.tab} className="tab-list">
        {children}
      </div>
    );
  }
}

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tab;
