import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Logo from './icons/Logo';
import Leaderboard from './Leaderboard';
import '../styles/components/Footer.scss';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              name
              items {
                title
                url
              }
            }
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              facebook
              twitter
              youtube
            }
          }
        }
      }
    `}
    render={(data) => {
      const social = data.allWordpressAcfOptions.edges[0].node;
      const menus = data.allWordpressWpApiMenusMenusItems.edges
        .filter(menu => menu.node.name === 'Footer')[0].node.items;

      return (
        <footer>
          <Leaderboard style={{ marginTop: 0 }} />
          <ul>
            <li className="footer-logo">
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <Link to="/">
                <Logo />
              </Link>
            </li>
            <li className="footer-social">
              <a title="Facebook" target="_blank" rel="noopener noreferrer" href={social.facebook}>
                <i className="icon-facebook" />
              </a>
              <a title="Twitter" target="_blank" rel="noopener noreferrer" href={social.twitter}>
                <i className="icon-twitter" />
              </a>
              <a title="Youtube" target="_blank" rel="noopener noreferrer" href={social.youtube}>
                <i className="icon-youtube" />
              </a>
            </li>
            {menus.map(menu => (
              <li key={menu.title}>
                <Link title={menu.title} to={menu.url}>
                  {menu.title}
                </Link>
              </li>
            ))}
          </ul>
        </footer>
      );
    }}
  />
);

export default Footer;
