import React from 'react';
import PropTypes from 'prop-types';
import Header from './HeaderScreen';
import Footer from './Footer';
import '../styles/style.scss';

const Layout = ({ children, isNotFoundPage }) => (
  <div className="wrapper-body">
    <Header />
    { children }
    {!isNotFoundPage && <Footer />}
  </div>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  isNotFoundPage: PropTypes.bool,
};

Layout.defaultProps = {
  isNotFoundPage: false,
};

export default Layout;
