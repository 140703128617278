import React from 'react';
import HeaderDefault from './HeaderDefault';
import HeaderMenu from './HeaderMenu';
import HeaderSearch from './HeaderSearch';

const HeaderScreen = () => (
  <div>
    <HeaderDefault />
    <HeaderMenu />
    <HeaderSearch />
  </div>
);

export default HeaderScreen;
