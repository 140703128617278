import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import '../styles/components/Box.scss';
import {
  resetHeaderState,
} from '../actions';

class Box extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.onMouseEnterTitle = this.onMouseEnterTitle.bind(this);
    this.onMouseLeaveTitle = this.onMouseLeaveTitle.bind(this);
  }

  onMouseEnterTitle() {
    this.setState({ hover: true });
  }

  onMouseLeaveTitle() {
    this.setState({ hover: false });
  }

  render() {
    const {
      slug,
      title,
      backgroundImage,
      time,
      categories,
    } = this.props;
    const { hover } = this.state;

    return (
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <Link
        onClick={() => {
          // Close Menu and Search
          const { resetHeader } = this.props;
          resetHeader();
        }}
        title={title}
        to={`/video/${slug}`}
        onMouseEnter={this.onMouseEnterTitle}
        onMouseLeave={this.onMouseLeaveTitle}
        onTouchStart={this.onMouseEnterTitle}
        onTouchEnd={this.onMouseLeaveTitle}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <div className="box-echo">
          <Img
            resolutions={backgroundImage}
            className="box-bg"
            style={hover ? { filter: 'grayscale(0%)' } : {}}
          />
          <div className="box-time">
            <i className="box-time-play icon-play" />
            <span>
              {time}
            </span>
          </div>
          <div className="box-text">
            <span>
              {categories[0]}
            </span>
            <h2>
              {renderHTML(title)}
            </h2>
          </div>
        </div>
      </Link>
    );
  }
}

Box.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  backgroundImage: PropTypes.shape(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  time: PropTypes.string.isRequired,
  resetHeader: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetHeader: () => {
    setTimeout(() => {
      dispatch(resetHeaderState());
    }, 500);
  },
});

export default connect(null, mapDispatchToProps)(Box);
