import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import Tabs from './Tabs';
import Tab from './Tab';
import BoxScreen from './BoxScreen';
import BoxRows from './BoxRows';
import { convertVideos, convertPlaylists } from '../utility';
import '../styles/components/HeaderSearch.scss';

class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { keyword: '' };
    this.search = this.search.bind(this);
  }

  search({ target }) {
    this.setState({
      keyword: target.value ? target.value : '',
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPost {
              edges {
                node {
                  slug
                  title
                  content
                  date
                  modified
                  playlist
                  categories {
                    name
                  }
                  featured_media {
                    localFile {
                      childImageSharp {
                        resolutions(width: 1920)  {
                          base64
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                      }
                    }
                  }
                  acf {
                    staff_pick
                    youtube_id
                    duration
                    call_to_action
                  }
                }
              }
            }
            allWordpressWpPlaylist {
              edges {
                node {
                  wordpress_id
                  name
                  slug
                  description
                  count
                  acf {
                    logo_background {
                      localFile {
                        childImageSharp {
                          resolutions(width: 1024)  {
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                          }
                        }
                      }
                    }
                    staff_pick
                    font_color
                    background_font_color
                    background_image {
                      localFile {
                        childImageSharp {
                          resolutions(width: 1920)  {
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                          }
                        }
                      }
                    }
                    featured_video {
                      post_name
                    }
                    brand_cover_desktop
                    brand_cover_tablet
                    brand_cover_mobile
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const { isSearch } = this.props;
          const { keyword } = this.state;
          let videos = convertVideos(data.allWordpressPost);
          let playlists = convertPlaylists(data.allWordpressWpPlaylist);

          videos = videos.filter(post => post.title.toLowerCase().indexOf(keyword) > -1);

          playlists = playlists
            .filter(playlist => (
              playlist.name.toLowerCase().indexOf(keyword) > -1 && playlist.count > 0
            ))
            .map((playlist) => {
              /* eslint-disable-next-line no-param-reassign */
              playlist.description = '';
              return playlist;
            });

          return (
            <div
              className={`header-search-wrapper ${(isSearch && 'is-active').toString()}`}
            >
              <div className="container">
                <div className="wrapper">
                  <input
                    type="search"
                    ref={(input) => {
                      this.searchInput = input;
                    }}
                    placeholder="Type to Search"
                    onChange={this.search}
                    value={keyword}
                  />
                </div>
              </div>
              {keyword && (
                <Tabs>
                  <Tab tabName="Video">
                    {videos.length === 0 ? (
                      <p className="no-result container">
                        ไม่มีว่ะ...
                      </p>
                    ) : (
                      <BoxScreen articles={videos} />
                    )}
                  </Tab>
                  <Tab tabName="Playlist">
                    {playlists.length === 0 ? (
                      <p className="no-result container">
                        ไม่มีว่ะ...
                      </p>
                    ) : (
                      <BoxRows playlists={playlists} />
                    )}
                  </Tab>
                </Tabs>
              )}
            </div>
          );
        }}
      />
    );
  }
}

HeaderSearch.propTypes = {
  isSearch: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSearch: state.header.isSearch,
});

export default connect(mapStateToProps)(HeaderSearch);
