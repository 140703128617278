import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../styles/components/Logo.scss';

const Logo = ({ isSearch }) => (
  <svg className={`logo ${isSearch ? 'is-active' : ''}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 105.2" style={{ width: 100 }} xmlSpace="preserve">
    <g>
      <g id="XMLID_2_">
        <g id="XMLID_3_">
          <g id="XMLID_4_">
            <path
              className="st0"
              d="M239,34.5c4.5,6.3,5.8,15.8,3.5,26.9l0,0c-2.4,11.3-7.2,20.2-14.3,26.5c-5.9,5.3-13.4,8.4-20.9,8.7
      c-0.5,0-1,0-1.5,0c-3.7,0-7.1-0.7-10-1.9l-12.9-5.7c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.9-0.4-1.8-0.9-2.6-1.5
      l-1.4,6.4c-0.4,2.1-2.3,3.7-4.4,3.7l-46.5,2.1c-0.1,0-0.1,0-0.2,0c-0.7,0-1.3-0.1-1.9-0.4l-11.2-5c-5.7,4.5-12.3,7-19.6,7.3
      c-0.6,0-1.1,0-1.6,0c-4.1,0-7.8-0.7-11-2.1l-12.9-5.7c-0.1,0-0.1-0.1-0.2-0.1c-2-0.9-3.8-2.1-5.5-3.6c0,0-0.1-0.1-0.1-0.1
      c-6.4,8.8-15.8,14.1-26.2,14.6c-0.5,0-1,0-1.6,0c-3.8,0-7.3-0.7-10.2-2l-12.9-5.7c-0.1,0-0.2-0.1-0.3-0.1
      c-3-1.4-5.6-3.5-7.5-6.2C0,84.4-1.2,75.3,1.1,64.3c1.1-5,2.8-9.8,5.1-14.3c1.8-3.5,4-6.7,6.4-9.6c6.7-7.8,15-12.1,24.1-12.5
      c0.4,0,0.9,0,1.3,0c3.3,0,6.2,0.6,8.9,1.7c0.1,0,0.2,0.1,0.3,0.1l12.9,5.7c2.3,1,4.3,2.5,6.1,4.3c0.1-0.1,0.2-0.2,0.3-0.3
      c0.6-0.7,1.2-1.3,1.8-1.9c6.7-6.6,14.6-10.2,23.4-10.6c0.5,0,1,0,1.4,0c3.6,0,7,0.6,10,1.9c0.1,0,0.3,0.1,0.4,0.2l12.9,5.7
      c1.4,0.6,2.7,1.4,3.9,2.3l6.4-30.4l0.1-0.7c0.4-2.1,2.3-3.6,4.4-3.7l19.9-0.9c0.1,0,0.1,0,0.2,0c0.7,0,1.3,0.1,1.9,0.4l12.9,5.7
      c2,0.9,3.2,3.1,2.7,5.3l-2.4,11.3l12.7,5.6c1.4,0.6,2.6,1.4,3.6,2.4l0,0c0,0,0.1-0.1,0.1-0.1c6.4-7,14.5-10.9,23.5-11.3
      c0.5,0,1,0,1.5,0c3.6,0,6.9,0.6,9.8,1.8c0.1,0,0.2,0.1,0.2,0.1l0,0l0,0l12.9,5.7C234.3,29.5,236.9,31.6,239,34.5z M224,47.4
      c0-0.7,0.1-1.4,0.1-2.1c0-5.2-1.2-9.5-3.4-12.5c-0.2-0.3-0.5-0.7-0.8-1c-1.2-1.4-2.7-2.5-4.4-3.2c-2.1-0.9-4.6-1.4-7.5-1.4
      c-0.4,0-0.8,0-1.2,0c-5.5,0.3-11.6,2.4-16.8,7.1c-5.3,4.7-9.8,12-12,22.8v0c-0.7,3.3-1.1,6.4-1.1,9.2c0,5.1,1.2,9.4,3.3,12.3
      c1.4,1.9,3.1,3.3,5.1,4.2c1.8,0.8,4,1.3,6.4,1.4c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.2,0c4.2-0.2,7.9-1.3,11.1-3
      c0.7-0.4,1.4-0.8,2.1-1.2c0.9-0.6,1.7-1.2,2.5-1.8c1.3-1.1,2.5-2.3,3.7-3.6c0.1-0.1,0.2-0.2,0.3-0.3c0.6-0.7,1.2-1.5,1.8-2.3
      c2.9-4.1,5.1-8.9,6.5-13.7c0.4-1.3,0.7-2.6,1-4C223.5,51.9,223.8,49.6,224,47.4z M167.1,48c0.7-3.1,1-5.9,1-8.4
      c0-1.7-0.2-3.2-0.5-4.5c0,0,0,0,0-0.1c-0.3-1.2-0.8-2.3-1.4-3.1c-0.7-0.9-1.5-1.6-2.5-2.1c-1-0.5-2.3-0.7-3.8-0.7
      c-0.2,0-0.4,0-0.6,0h0c-1.4,0.1-2.8,0.5-4.2,1.1c-1.4,0.7-2.8,1.6-4,2.7c-2.5,2.2-4.7,5.2-6.1,8l-0.3,0.5l0,0
      c-0.2,0.3-0.5,0.5-0.8,0.6c-0.4,0-0.7-0.1-1-0.4l-0.7-0.8c-0.2-0.3-0.3-0.6-0.2-1l0-0.2l0,0c0.3-1.3,0.7-2.6,1-3.9
      c0.5-1.6,0.9-3.1,1.2-4.6l0,0l3.9-18.1l1.1-5.3l-16,0.7L116.6,87l13.2-0.6l2.9-0.1l5.6-26.8v0c0.4-1.7,0.7-3.4,1.2-5
      c0.3-1,0.7-1.9,1.1-2.8c0.3-0.5,0.6-1,0.9-1.4c0.2-0.2,0.3-0.4,0.5-0.6c0.5-0.5,1.2-1,1.9-1.2c0.6-0.2,1.2-0.3,1.8-0.4
      c0.1,0,0.2,0,0.2,0c0.6,0,1.2,0.1,1.7,0.3c0.5,0.2,1,0.6,1.3,1.1v0c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.2,0.1,0.4,0.2,0.5
      c0.1,0.5,0.2,1.1,0.2,1.7c0,0.9-0.1,1.8-0.3,2.7c-0.2,1-0.4,2-0.6,3l-5.8,27.5l13.1-0.6l2.9-0.1L167.1,48z M110,52
      c0-0.4,0-0.8,0-1.1c0-2.6-0.4-5-1.1-7c-0.7-2.1-1.8-3.8-3.2-5.3v0c-0.1-0.1-0.2-0.2-0.2-0.2c-1.5-1.5-3.2-2.6-5.2-3.4
      c-2.1-0.8-4.4-1.2-7.1-1.2c-0.4,0-0.8,0-1.2,0c-3.6,0.2-6.9,1-10.1,2.4c-3.2,1.4-6.1,3.5-8.9,6.3c-5.5,5.5-9.1,12.3-10.6,20.5
      c-0.4,2.5-0.7,4.8-0.7,6.9c0,2.5,0.3,4.9,0.9,7c0.6,2.1,1.5,4,2.7,5.6c1.6,2.2,3.6,3.9,6,5c1.8,0.8,3.9,1.4,6.2,1.6
      c0.8,0.1,1.6,0.1,2.4,0.1c0.4,0,0.9,0,1.3,0c6.1-0.3,11.5-2.4,16.2-6.2c0.7-0.6,1.3-1.2,2-1.8c3.2-3.3,5.8-7.4,7.5-12.3
      l-13.2-0.6c-0.6,1.8-1.4,3.4-2.2,4.6c-1,1.5-2.2,2.6-3.6,3.2c-0.7,0.3-1.4,0.6-2.2,0.7c-0.3,0-0.5,0.1-0.8,0.1
      c-0.1,0-0.3,0-0.4,0c-0.9,0-1.8-0.2-2.5-0.5c-0.8-0.3-1.4-0.9-1.9-1.6c-0.3-0.4-0.6-1-0.8-1.5c-0.3-0.7-0.5-1.6-0.6-2.5v0
      c0-0.4-0.1-0.8-0.1-1.3c0-2,0.3-4.7,0.9-8c0.5-2.7,1.1-5.1,1.8-7.2c0.7-2,1.6-3.7,2.6-5.1c0.3-0.4,0.7-0.9,1-1.2
      c0.4-0.4,0.8-0.7,1.2-1c0.5-0.4,1.1-0.7,1.7-0.9c0.6-0.2,1.2-0.3,1.9-0.4c0.1,0,0.2,0,0.3,0c0.9,0,1.7,0.2,2.4,0.5
      c0.7,0.4,1.3,1,1.8,1.7c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.4,0.3,0.8,0.5,1.2v0c0.2,0.6,0.3,1.4,0.4,2.4c0.1,0.6,0.1,1.4,0.1,2.2
      l9.2-1.3L110,52z M53,53.9c0-0.5,0-0.9,0-1.4c0-3.6-0.5-7-1.9-10l0,0c-0.6-1.2-1.2-2.3-2-3.2c-0.9-1.1-1.9-2-3.1-2.7
      c-2.2-1.3-4.8-2-8-2c0,0,0,0,0,0c-0.3,0-0.7,0-1,0c-3.9,0.2-7.5,1.3-10.8,3.1c-3.2,1.8-6.1,4.3-8.7,7.2c-5,5.8-8.5,13.5-10,20.7
      c-0.7,3.3-1,6.3-1,9c0,2.5,0.3,4.7,0.8,6.7c0.5,2,1.3,3.7,2.4,5.1c1.3,1.8,3,3.2,5.1,4.1c1.8,0.8,3.9,1.3,6.4,1.4
      c0.4,0,0.8,0,1.2,0c0.4,0,0.8,0,1.3,0c5.6-0.3,10.8-2.3,15.2-5.7c2.5-2,4.8-4.4,6.7-7.3c1.1-1.6,2.1-3.4,2.9-5.3L33,74.5
      c-0.4,0.6-0.9,1.2-1.4,1.8C30.9,77,30,77.6,29.1,78c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.2-1.1,0.2c-0.1,0-0.2,0-0.3,0
      c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.4-1.2v0c-0.4-0.5-0.7-1.2-0.8-1.9c-0.2-0.7-0.2-1.5-0.2-2.3c0-1.4,0.2-3,0.6-4.9
      l0.1-0.4c0.1-0.5,0.5-0.9,1-0.9l11.1-0.5l16.2-0.7c0.2-1.2,0.5-2.3,0.7-3.5C52.5,59.2,52.9,56.5,53,53.9z"
            />
            <path
              className="st0"
              d="M208.3,47.1c0,2.3-0.4,5.1-1.1,8.5l0,0c-0.4,2-1,4.3-1.7,6.5c-0.7,2.2-1.6,4.2-2.8,5.9
      c-0.8,1.1-1.7,2-2.9,2.6c-0.3,0.2-0.7,0.3-1,0.4c0,0-0.1,0-0.1,0l-1,0.1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.5,0-1-0.1-1.4-0.2
      c-0.2-0.1-0.3-0.1-0.5-0.2c-0.6-0.3-1.1-0.7-1.5-1.3c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3-0.5-0.5-1.1-0.6-1.7c0,0,0-0.1,0-0.1
      c-0.2-0.7-0.3-1.5-0.3-2.3c0-0.2,0-0.5,0-0.7c0-1.5,0.2-3.1,0.5-5c0.2-1.1,0.4-2.2,0.6-3.4c0,0,0,0,0,0c0.5-2.7,1.2-5,1.9-7
      c0.7-2,1.5-3.7,2.5-5c0.2-0.2,0.3-0.4,0.5-0.6c0.5-0.6,1-1.1,1.5-1.5c1-0.8,2.1-1.2,3.3-1.3c0.1,0,0.2,0,0.2,0
      c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.7,0.2,1,0.3c0.5,0.2,0.9,0.6,1.2,1c0.1,0.1,0.2,0.2,0.2,0.3v0c0.2,0.3,0.3,0.5,0.5,0.8
      c0.2,0.5,0.4,1,0.5,1.5C208.2,45,208.3,46,208.3,47.1z"
            />
            <path
              className="st0"
              d="M26.9,58.1l-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
      c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
      c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0.1-0.5c0.1-0.3,0.1-0.6,0.2-0.9
      c0.3-1,0.7-2.1,1.2-3.2c0.7-1.4,1.7-2.7,2.9-3.6c0.6-0.4,1.2-0.7,1.8-0.9c0.3-0.1,0.7-0.1,1.1-0.2c0.1,0,0.2,0,0.2,0
      c0.7,0,1.3,0.1,1.9,0.4c0,0,0,0,0,0c0.6,0.3,1.1,0.7,1.5,1.3c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4
      c0.2,0.4,0.4,0.9,0.5,1.4c0.2,0.8,0.3,1.6,0.3,2.4c0,0.9-0.1,1.9-0.3,2.7c0,0,0,0,0,0l-0.1,0.4c-0.1,0.5-0.5,0.8-1,0.9L29.7,58
      l-0.9,0L26.9,58.1z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Logo.propTypes = {
  isSearch: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSearch: state.header.isSearch,
});

export default connect(mapStateToProps)(Logo);
