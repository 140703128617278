import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import renderHTML from 'react-render-html';
import { StaticQuery, graphql } from 'gatsby';
import icon from '../images/icon/favicon.png';

const ShareMeta = ({ meta }) => {
  const description = meta.description.replace(/(<([^>]+)>)/ig, '');

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <Helmet>
          <html lang="th" />
          <title>
            {renderHTML(meta.title)}
          </title>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="apple-mobile-web-app-capable" content="yes" />

          <meta name="description" content={description} />
          <meta property="og:site_name" content="Echo" />
          <meta property="og:title" content={renderHTML(meta.title)} />
          <meta property="og:description" content={description} />
          {/* eslint-disable no-undef */}
          <meta property="og:url" content={typeof window !== 'undefined' && window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={data.site.siteMetadata.siteUrl.concat(meta.thumbnail)} />
          <meta property="og:locale" content="th" />
          <meta property="og:type" content="article" />
          <meta property="og:updated_time" content={meta.modifiedTime} />

          <meta property="article:section" content="จิปาถะ" />
          <meta property="article:modified_time" content={meta.modifiedTime} />

          <meta name="twitter:title" content={renderHTML(meta.title)} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={data.site.siteMetadata.siteUrl.concat(meta.thumbnail)} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={typeof window !== 'undefined' && window.location.href} />

          <link rel="canonical" href={typeof window !== 'undefined' && window.location.href} />
          <link rel="icon" type="image/png" href={icon} />

          <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
        </Helmet>
      )}
    />
  );
};

ShareMeta.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
  }),
};

ShareMeta.defaultProps = {
  meta: {
    thumbnail: '',
  },
};

export default ShareMeta;
