import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import animation from '../services/animation';
import '../styles/components/HeaderMenu.scss';
import {
  activeMenu,
  selectMenu,
  resetHeaderState
} from '../actions';

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.changeMenuWithAnimation = this.changeMenuWithAnimation.bind(this);
  }

  changeMenuWithAnimation(e, index) {
    const { menuSelected, changeMenu } = this.props;
    const li = e.target.parentElement;
    const ul = li.parentElement;

    if (menuSelected !== index) {
      const y = -((index * (li.offsetHeight + 10)));
      animation.moveTo(ul, { y });
    } else {
      animation.moveTo(ul, { y: 0 });
    }

    if (!li.classList.contains('is-active')) {
      const liSubMenu = li.querySelectorAll('.sub-menu li');
      animation.staggerFrom(liSubMenu);
    }

    changeMenu(index);
  }

  render() {
    const {
      isActiveMenu,
      menuSelected,
      menuClose,
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  items {
                    title
                    url
                    wordpress_children {
                      title
                      url
                      logo_background_acf {
                        source_url
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const menus = data.allWordpressWpApiMenusMenusItems.edges
            .filter(menu => menu.node.name === 'Header')[0].node.items;

          return (
            <div className={`header-menu-wrapper ${(isActiveMenu && 'is-active').toString()}`}>
              <MediaQuery minWidth={768}>
                <div
                  className="header-menu-closer"
                  onTouchStart={menuClose}
                  onMouseEnter={menuClose}
                  role="presentation"
                />
              </MediaQuery>
              <nav className="container">
                <MediaQuery maxWidth={767}>
                  <ul style={{ transform: menuSelected === -1 ? 'initial' : '' }}>
                    {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                    {menus.map((menu, index) => (
                      <li
                        key={menu.title}
                        className={menuSelected === index ? 'is-active' : ''}
                        onClick={e => this.changeMenuWithAnimation(e, index)}
                        role="menuitem"
                      >
                        <span>
                          {menu.title}
                        </span>
                        <ul className="sub-menu">
                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                          {menu.wordpress_children.map(item => (
                            <li key={item.title}>
                              <Link
                                onClick={() => {
                                  // Close Menu and Search
                                  const { resetHeader } = this.props;
                                  resetHeader();
                                }}
                                to={item.url}
                              >
                                {item.title}
                                {item.logo_background_acf.source_url && (
                                  <img
                                    alt={item.title}
                                    src={item.logo_background_acf.source_url}
                                    style={{
                                      width: '30px',
                                      height: 'auto',
                                      marginLeft: '10px',
                                    }}
                                  />
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </MediaQuery>
                <MediaQuery minWidth={768}>
                  <div className="header-menu-overflow">
                    {menus.map((menu, index) => (
                      <ul key={menu.title} className={(menuSelected === index) ? 'is-active' : ''}>
                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                        {menu.wordpress_children.map(item => (
                          <li
                            key={item.title}
                          >
                            <Link to={item.url}>
                              <h6>
                                {item.title}
                                {item.logo_background_acf.source_url && (
                                  <img
                                    alt={item.title}
                                    src={item.logo_background_acf.source_url}
                                    style={{
                                      width: '30px',
                                      height: 'auto',
                                      marginLeft: '10px',
                                    }}
                                  />
                                )}
                              </h6>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </MediaQuery>
              </nav>
            </div>
          );
        }}
      />
    );
  }
}

HeaderMenu.propTypes = {
  menuSelected: PropTypes.number.isRequired,
  isActiveMenu: PropTypes.bool.isRequired,
  changeMenu: PropTypes.func.isRequired,
  menuClose: PropTypes.func.isRequired,
  resetHeader: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  menuSelected: state.header.menuSelected,
  isActiveMenu: state.header.isActiveMenu,
});

const mapDispatchToProps = dispatch => ({
  menuClose: () => dispatch(activeMenu(false)),
  changeMenu: index => dispatch(selectMenu(index)),
  resetHeader: () => {
    setTimeout(() => {
      dispatch(resetHeaderState());
    }, 500);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderMenu);
