import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import '../styles/components/Boxrow.scss';
import {
  resetHeaderState,
} from '../actions';

class BoxRow extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
    this.onMouseEnterTitle = this.onMouseEnterTitle.bind(this);
    this.onMouseLeaveTitle = this.onMouseLeaveTitle.bind(this);
  }

  onMouseEnterTitle() {
    this.setState({ hover: true });
  }

  onMouseLeaveTitle() {
    this.setState({ hover: false });
  }

  render() {
    const {
      name,
      slug,
      logo,
      description,
      count,
      backgroundFontColor,
      fontColor,
      backgroundImage,
      fullPage,
      paddingText,
      isCategory,
    } = this.props;
    const { hover } = this.state;

    return (
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <Link
        title={name}
        onClick={() => {
          // Close Menu and Search
          const { resetHeader } = this.props;
          resetHeader();
        }}
        to={isCategory ? `/category/${slug}` : `/playlist/${slug}`}
        className={`playlist${fullPage ? ' full-page' : ''}`}
        onMouseEnter={this.onMouseEnterTitle}
        onMouseLeave={this.onMouseLeaveTitle}
        onTouchStart={this.onMouseEnterTitle}
        onTouchEnd={this.onMouseLeaveTitle}
      >
        <Img
          resolutions={backgroundImage}
          className="playlist-bg"
          style={{
            filter: hover && 'grayscale(0%)',
          }}
        />
        <div className="container">
          <div className={`wrapper${paddingText ? ' padding-text' : ''}`}>
            {logo && (
              <div>
                <img src={logo} alt={name} width="60" height="60" />
                <br />
              </div>
            )}
            <div className="playlist-title">
              {fullPage ? (
                <h1
                  style={hover ? { background: backgroundFontColor, color: fontColor } : {}}
                >
                  {name}
                </h1>
              ) : (
                <h2
                  style={hover ? { background: backgroundFontColor, color: fontColor } : {}}
                >
                  {name}
                </h2>
              )}
            </div>
            {fullPage ? (
              <div className="playlist-description">
                <p
                  style={hover ? { background: backgroundFontColor, color: fontColor } : {}}
                >
                  {description}
                </p>
              </div>
            ) : (
              <p
                style={hover ? { background: backgroundFontColor, color: fontColor } : {}}
              >
                {count.toString().concat(' New Stories')}
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  }
}

BoxRow.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  logo: PropTypes.string,
  description: PropTypes.string,
  count: PropTypes.number,
  backgroundFontColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  backgroundImage: PropTypes.shape(PropTypes.any).isRequired,
  fullPage: PropTypes.bool,
  paddingText: PropTypes.bool,
  isCategory: PropTypes.bool,
  resetHeader: PropTypes.func.isRequired,
};

BoxRow.defaultProps = {
  logo: '',
  description: '',
  count: 0,
  fullPage: false,
  paddingText: false,
  isCategory: false,
};

const mapDispatchToProps = dispatch => ({
  resetHeader: () => {
    setTimeout(() => {
      dispatch(resetHeaderState());
    }, 1000);
  },
});

export default connect(null, mapDispatchToProps)(BoxRow);
