import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import Logo from './icons/Logo';
import '../styles/components/HeaderDefault.scss';

import {
  activeMenu,
  selectMenu,
  searchVideo,
} from '../actions';

const HeaderDefault = ({
  isActiveMenu,
  menuActive,
  changeMenu,
  closeMenuAndSearch,
  menuSelected,
  isSearch,
  searchActive,
}) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              name
              items {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const menus = data.allWordpressWpApiMenusMenusItems.edges
        .filter(menu => menu.node.name === 'Header')[0].node.items;

      return (
        <header>
          <MediaQuery maxWidth={767}>
            <div className="container">
              <div
                className={`header-menu ${isActiveMenu && 'is-active'}`}
              >
                <div
                  className="header-menu-button"
                  onClick={isActiveMenu ? closeMenuAndSearch : menuActive}
                  role="presentation"
                >
                  <i
                    className={isActiveMenu ? 'icon-cross' : 'icon-hamburger'}
                    style={{
                      color: isSearch ? 'black' : 'white',
                      transition: isSearch ? 'color 0.1s 0.2s ease' : 'color 0.1s ease',
                    }}
                  />
                </div>
              </div>
              <div className="header-logo">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div
                className="header-search"
              >
                <div
                  className="header-search-button"
                  onClick={isSearch ? closeMenuAndSearch : searchActive}
                  onKeyPress={isSearch ? closeMenuAndSearch : searchActive}
                  role="presentation"
                >
                  <i
                    className={isSearch ? 'icon-cross' : 'icon-search'}
                  />
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <div className="container">
              <div className="header-logo">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to="/" onClick={() => closeMenuAndSearch()}>
                  <Logo />
                </Link>
              </div>
              <div
                className={`header-menu ${((isActiveMenu || isSearch) && 'is-active').toString()}`}
              >
                <ul>
                  {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                  {/* eslint-dsiable jsx-a11y/mouse-events-have-key-events */}
                  {!isSearch && menus.map((item, index) => (
                    <li
                      key={item.title}
                      className={`${(menuSelected === index && 'is-active').toString()}`}
                      onMouseOver={() => {
                        menuActive();
                        changeMenu(index);
                      }}
                      role="menuitem"
                    >
                      {item.title}
                    </li>
                  ))}
                  <li
                    onClick={isSearch ? closeMenuAndSearch : searchActive}
                    onKeyPress={isSearch ? closeMenuAndSearch : searchActive}
                    role="presentation"
                  >
                    <i className={isSearch ? 'icon-cross' : 'icon-search'} />
                  </li>
                </ul>
              </div>
            </div>
          </MediaQuery>
        </header>
      );
    }}
  />
);

HeaderDefault.propTypes = {
  menuActive: PropTypes.func.isRequired,
  searchActive: PropTypes.func.isRequired,
  changeMenu: PropTypes.func.isRequired,
  closeMenuAndSearch: PropTypes.func.isRequired,
  menuSelected: PropTypes.number.isRequired,
  isActiveMenu: PropTypes.bool.isRequired,
  isSearch: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSearch: state.header.isSearch,
  isActiveMenu: state.header.isActiveMenu,
  menuSelected: state.header.menuSelected,
});

const mapDispatchToProps = dispatch => ({
  changeMenu: index => dispatch(selectMenu(index)),
  menuActive: () => {
    dispatch(activeMenu(true));
    dispatch(searchVideo(false));
  },
  searchActive: () => {
    dispatch(activeMenu(false));
    dispatch(searchVideo(true));
  },
  closeMenuAndSearch: () => {
    dispatch(activeMenu(false));
    dispatch(searchVideo(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderDefault);
