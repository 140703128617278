/*
 * This file is used in gatsby-node which is supported only ES5
 */

const convertVideoNode = (video) => {
  const match = video.acf.duration.match(/(\d+)/gm);
  const mins = match[0];
  const secs = match[1] ? '0'.concat(match[1]).slice(-2) : '00';
  const duration = `${mins}:${secs}`;

  return Object.assign(
    {}, {
      slug: video.slug,
      title: video.title,
      content: video.content,
      publishTime: video.date,
      modifiedTime: video.modified,
      time: duration,
      videoID: video.acf.youtube_id,
      callToAction: video.acf.call_to_action,
      staffPick: video.acf.staff_pick,
    },
    video.featured_media && video.featured_media.localFile ? { backgroundImage: video.featured_media.localFile.childImageSharp.resolutions } : { backgroundImage: '' },
    video.categories ? { categories: video.categories.map(item => item.name) } : { categories: [] },
    video.playlist ? { playlist: video.playlist } : { playlist: [] },
  );
};

const convertPageNode = page => (
  Object.assign(
    {}, {
      slug: page.slug,
      title: page.title,
      content: page.content,
      excerpt: page.excerpt,
      publishedTime: page.date,
      modifiedTime: page.modified,
    },
    page.featured_media && page.featured_media.localFile ? { backgroundImage: page.featured_media.localFile.childImageSharp.resolutions } : { backgroundImage: '' },
    page.categories ? { categories: page.categories.map(item => item.name) } : { categories: [] },
    page.playlist ? { playlist: page.playlist } : { playlist: [] },
  )
);

const convertPlaylistNode = playlist => (
  Object.assign(
    {}, {
      id: playlist.wordpress_id,
      name: playlist.name,
      slug: playlist.slug,
      description: playlist.description,
      count: playlist.count,
      staffPick: playlist.acf.staff_pick,
      backgroundFontColor: playlist.acf.background_font_color,
      fontColor: playlist.acf.font_color,
      featured_slug: playlist.acf.featured_video.post_name,
      brand_cover_desktop: playlist.acf.brand_cover_desktop,
      brand_cover_tablet: playlist.acf.brand_cover_tablet,
      brand_cover_mobile: playlist.acf.brand_cover_mobile,
    },
    playlist.acf.background_image && playlist.acf.background_image.localFile ? { backgroundImage: playlist.acf.background_image.localFile.childImageSharp.resolutions } : { backgroundImage: '' },
    playlist.acf.logo_background && playlist.acf.logo_background.localFile ? { logo: playlist.acf.logo_background.localFile.childImageSharp.resolutions } : { logo: '' },
  )
);

const convertCategoryNode = (category) => {
  const {
    name,
    slug,
    description,
    count,
    acf,
  } = category;

  return Object.assign(
    {}, {
      name,
      slug,
      description,
      count,
      backgroundFontColor: acf.background_font_color,
      fontColor: acf.font_color,
      featured_slug: acf.featured_video.post_name,
      brand_cover_desktop: acf.brand_cover_desktop,
      brand_cover_tablet: acf.brand_cover_tablet,
      brand_cover_mobile: acf.brand_cover_mobile,
    },
    acf.background_image && acf.background_image.localFile ? { backgroundImage: acf.background_image.localFile.childImageSharp.resolutions } : { backgroundImage: '' },
  );
};

const convertPages = rootGraph => rootGraph.edges.map(edge => convertPageNode(edge.node));
const convertVideos = rootGraph => rootGraph.edges.map(edge => convertVideoNode(edge.node));
const convertPlaylists = rootGraph => rootGraph.edges.map(edge => convertPlaylistNode(edge.node));
const convertCategories = rootGraph => rootGraph.edges.map(edge => convertCategoryNode(edge.node));

module.exports = {
  convertVideos,
  convertPages,
  convertCategories,
  convertPlaylists,
};
