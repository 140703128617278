import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/components/Tabs.scss';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: 0,
    };

    this.changeTab = (index) => {
      this.setState({ isActive: index });
    };
  }

  render() {
    const { children } = this.props;
    const { isActive } = this.state;

    return (
      <div className="tabs">
        <div className="tab-head container">
          <ul>
            {children.map((node, index) => (
              <li
                className={index === isActive ? 'is-active' : ''}
                onClick={() => this.changeTab(index)}
                onKeyPress={() => this.changeTab(index)}
                role="presentation"
                key={node.props.tabName}
              >
                {node.props.tabName}
              </li>
            ))}
          </ul>
        </div>
        {children[isActive]}
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
