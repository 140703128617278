import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import '../styles/components/BoxScreen.scss';

class BoxScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { until: 4 };
    this.loadmore = this.loadmore.bind(this);
  }

  loadmore() {
    this.setState(prevState => ({
      until: prevState.until + 4,
    }));
  }

  render() {
    const { title, articles, moreButton } = this.props;
    const { until } = this.state;
    const articlesClone = moreButton ? articles.slice(0, until) : articles;

    return (
      <section className="box-screen">
        <div className="container">
          {/* eslint-disable-next-line react/no-danger */}
          {title && <h3 className="box-screen-title" dangerouslySetInnerHTML={{ __html: title }} />}
          <div className="box-screen-contents">
            {articlesClone.map(article => <Box key={article.slug} {...article} />)}
          </div>
          {moreButton && until < articles.length && (
            <p style={{ textAlign: 'center', marginTop: 50 }}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                className="loadmore"
                onClick={this.loadmore}
                role="presentation"
              >
                โหลดเพิ่ม
              </span>
            </p>
          )}
        </div>
      </section>
    );
  }
}

BoxScreen.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  moreButton: PropTypes.bool,
};

BoxScreen.defaultProps = {
  title: '',
  moreButton: false,
};

export default BoxScreen;
