import React from 'react';
import PropTypes from 'prop-types';
import BoxRow from './BoxRow';

const BoxRows = ({ playlists, paddingText }) => (
  <section className="playlist-screen">
    {playlists.map(playlist => (
      <BoxRow key={playlist.name} paddingText={paddingText} {...playlist} />
    ))}
  </section>
);

BoxRows.propTypes = {
  playlists: PropTypes.arrayOf(PropTypes.any).isRequired,
  paddingText: PropTypes.bool,
};

BoxRows.defaultProps = {
  paddingText: false,
};

export default BoxRows;
