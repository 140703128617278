import { TweenMax } from 'gsap';

const duration = 0.5;

function staggerFrom(target) {
  return TweenMax.staggerFrom(target, duration, {
    opacity: 0,
    x: 100,
  }, 0.2);
}

function moveTo(target, pos) {
  return TweenMax.to(target, duration, pos);
}

function moveFrom(target, pos) {
  return TweenMax.from(target, duration, pos);
}

export default {
  staggerFrom,
  moveTo,
  moveFrom,
};
